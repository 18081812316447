<template>
  <div>
    <div v-for="(item, index) in znMarri"
         :key="index">
      <md-field>
        <md-input-item v-model="item.name"
                       title="子女姓名"
                       placeholder="请输入姓名"
                       align="right"></md-input-item>
        <md-input-item v-model="item.yjmoney"
                       title="预备婚嫁金"
                       placeholder="请输入金额"
                       align="right"></md-input-item>
        <md-input-item v-model="item.yjages"
                       title="预计结婚年龄"
                       placeholder="请输入年龄"
                       align="right"></md-input-item>
      </md-field>
    </div>
    <md-button :style="'background:'+getStorage('theme','')"
               class="btn_bc"
               size="large"
               @click="marriSave()"
               :inactive="btnloading">保存</md-button>
  </div>
</template>

<script>
import { addMarry } from '@/api/nbs/nbsinfo/index'
import { Toast } from 'mand-mobile'
export default {
  components: {
    [Toast.name]: Toast
  },
  name: 'otherdk',
  props: {
    znMarri: Array,
  },
  data () {
    return {
      btnloading: false
    }
  },
  methods: {
    marriSave () {
      this.btnloading = true
      addMarry({ abtComNbsZnmarry: this.znMarri }).then(res => {
        Toast.info('保存成功', 1000)
      }).finally(() => {
        this.btnloading = false
      })
    }
  }
}
</script>

<style scoped>
.btn_bc {
  margin-top: 1rem;
  color: #fff;
  background-color: rgb(34, 139, 207);
}
.expenses .gh_time-input {
  width: 400px;
  margin-left: 120px;
}
.md-field-item.is-solid /deep/ .md-field-item-title {
  width: 2.2rem;
  font-size: 0.34rem;
}
</style>
