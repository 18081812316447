/*
 * @Author: 李波
 * @Date: 2019-11-20 14:48:19
 * @LastEditors  : 李波
 * @LastEditTime : 2020-02-09 11:19:44
 */
import axios from '@/lib/api.request'

// 创建家庭
export const addFamliy = data =>
  axios.post('/nbs/abtComNbs/addfamily', data)

// 列表查询
export const famliyList = params =>
  axios.get('/nbs/abtComNbs/list', { params })

// 本人配偶添加
export const manwifeAdd = data =>
  axios.post('/nbs/abtComNbs/addfamilymembersone', data)

// 成员添加
export const menberAdd = data =>
  axios.post('/nbs/abtComNbs/addfamilymemberstwo', data)

// 子女婚嫁保存接口
export const addMarry = data =>
  axios.post('/nbs/abtComNbs/addMarry', data)

// 其他费用保存
export const addOtherdk = data =>
  axios.post('/nbs/abtComNbs/addOtherdk', data)

// 家庭住房 租房
export const addhouse = data =>
  axios.post('/nbs/abtComNbs/addhouse', data)

// 教育费用保存
export const addEducation = data =>
  axios.post('/nbs/abtComNbs/addEducation', data)

// 报告查看
export const reportShow = params =>
  axios.get('/nbs/abtComNbs/show', { params })

// 信息录入详情
export const showfamilyInfo = params =>
  axios.get('/nbs/abtComNbs/showfamilyInfo', { params })

// 已备费用添加
export const addFamilyv = data =>
  axios.post('/nbs/abtComNbs/addFamilyv', data)

// 家庭成员删除
export const delfamily = params =>
  axios.delete('/nbs/abtComNbs/delfamily', { params })

// 其他贷款项删除
export const deleteOther = data =>
  axios.post('/nbs/abtComNbs/deleteOther', data)
