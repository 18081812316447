<template>
  <div>
    <div>
      <div v-for="(item, index) in abtComNbsEducation"
           :key="index">
        <div class="jt_member person"
             @click="educationzd(index)"
             style="margin-top: 20px">
          <span>子女{{index+1}}</span>
          <img src="@/assets/abt/img/jtright.png"
               v-if="!educcazd[index]">
          <img src="@/assets/abt/img/jtbottum.png"
               v-if="educcazd[index]">
        </div>
        <div v-show="educcazd[index]">
          <md-field>
            <md-input-item v-model="item.name"
                           title="子女姓名"
                           placeholder="请输入姓名"
                           align="right"></md-input-item>
            <md-input-item v-model="item.playschool"
                           title="幼儿园"
                           placeholder="请输入花费金额"
                           align="right">
              <div slot="right">万元</div>
            </md-input-item>
            <md-input-item v-model="item.primaryschool"
                           title="小学"
                           placeholder="请输入花费金额"
                           align="right">
              <div slot="right">万元</div>
            </md-input-item>
            <md-input-item v-model="item.midschool"
                           title="初中"
                           placeholder="请输入花费金额"
                           align="right">
              <div slot="right">万元</div>
            </md-input-item>
            <md-input-item v-model="item.highschool"
                           title="高中"
                           placeholder="请输入花费金额"
                           align="right">
              <div slot="right">万元</div>
            </md-input-item>
            <md-input-item v-model="item.university"
                           title="大学"
                           placeholder="请输入花费金额"
                           align="right">
              <div slot="right">万元</div>
            </md-input-item>
            <md-input-item v-model="item.graduate"
                           title="研究生"
                           placeholder="请输入花费金额"
                           align="right">
              <div slot="right">万元</div>
            </md-input-item>
            <md-input-item v-model="item.doctor"
                           title="博士"
                           placeholder="请输入花费金额"
                           align="right">
              <div slot="right">万元</div>
            </md-input-item>
          </md-field>
        </div>
      </div>
      <md-button :style="'background:'+getStorage('theme','')"
                 class="btn_bc"
                 size="large"
                 @click="educaSave"
                 v-if="abtComNbsEducation.length>0"
                 :inactive="btnloading">保存</md-button>
    </div>
  </div>
</template>

<script>
import { Toast } from 'mand-mobile'
import { addEducation } from '@/api/nbs/nbsinfo/index'
export default {
  components: {
    [Toast.name]: Toast
  },
  name: 'education',
  props: {
    abtComNbsEducation: Array
  },
  data () {
    return {
      educcazd: [],
      btnloading: false
    }
  },
  watch: {
    'abtComNbsEducation' () {
      for (let i = 0; i < this.abtComNbsEducation.length; i++) {
        this.educcazd.push(false)
      }
    }
  },
  methods: {
    educationzd (index) {
      this.educcazd[index] = !this.educcazd[index]
      this.$forceUpdate()
    },
    educaSave () {
      this.btnloading = true
      addEducation({ abtComNbsEducation: this.abtComNbsEducation }).then(res => {
        Toast.info('保存成功', 1000)
      }).finally(() => {
        this.btnloading = false
      })
    }
  }
}
</script>

<style scoped>
.btn_bc {
  margin-top: 1rem;
  color: #fff;
  background-color: rgb(34, 139, 207);
}
.expenses .gh_time-input {
  width: 400px;
  margin-left: 120px;
}
.md-field-item.is-solid /deep/ .md-field-item-title {
  width: 2.2rem;
  font-size: 0.34rem;
}
.jt_member img {
  vertical-align: middle;
  float: right;
  width: 0.4rem;
  margin-top: 0.03rem;
}
</style>
