<!--
 * @Author: 李波
 * @Date: 2019-11-08 17:23:12
 * @LastEditors: 刘格优
 * @LastEditTime: 2020-05-28 19:02:15
 -->
<template>
  <div class="container">
    <div class="top"
         :style="'background:'+getStorage('theme','')">
      <ul>
        <!--  -->
        <li @click="topswitch('0')"
            :style="isShow==='0'?'color:#ecf9f5':''">
          <div align="center">
            <img src="@/assets/abt/img/lrjt.png">
            <p>家庭资料</p>
          </div>
        </li>
        <li @click="topswitch('1')"
            :style="isShow==='1'?'color:#ecf9f5':''">
          <div align="center">
            <img src="@/assets/abt/img/lryb.png">
            <p>应备费用</p>
          </div>
        </li>
        <li @click="topswitch('2')"
            :style="isShow==='2'?'color:#ecf9f5':''">
          <div align="center">
            <img src="@/assets/abt/img/lryib.png">
            <p>已备费用</p>
          </div>
        </li>
      </ul>
    </div>
    <div class="hw_sty"
         :style="'background:'+getStorage('theme','')"
         @click="toShare">
      查看报告
    </div>
    <div class="content"
         :style="{'height':height+'px'}">
      <!-- 家庭资料模块 -->
      <div class="formation"
           v-show="isShow==='0'">
        <div class="content_left"
             :style="'background:'+getStorage('theme','')">
          <ul>
            <li @click="familyqh('0')"
                :style="familyvalue==='0'?'color:#ecf9f5':''">
              家庭信息
            </li>
            <li @click="familyqh('1')"
                :style="familyvalue==='1'?'color:#ecf9f5':''">家庭成员</li>
          </ul>
        </div>
        <div class="content_right">
          <div class="faminfo"
               v-show="familyvalue==='0'">
            <h2>家庭信息</h2>
            <div>
              <familyinfo ref="familyinfo"
                          :manwife="manwife"
                          :credtype="credtype"></familyinfo>
            </div>
          </div>
          <!-- 家庭成员模块-->
          <div class="fa_member"
               v-show="familyvalue=== '1'">
            <div class="jt_member jt_title">
              <span>家庭成员</span>
              <img src="@/assets/abt/img/meadd.png"
                   @click="cyadd">
            </div>
            <familymember ref="familymember"
                          :jtcy="jtcy"
                          :familyzd="familyzd"
                          :RelationValue="RelationValue"
                          :isRelation="isRelation"
                          :ismeDateValue="ismeDateValue"
                          :ismeDate="ismeDate"
                          :rytypelist="rytypelist"
                          :rytypeValue="rytypeValue"
                          :abtComNbsEducation="abtComNbsEducation"
                          :ryOptions="ryOptions"
                          :credtype="credtype"></familymember>
          </div>
        </div>
      </div>
      <!--应备费用模块-->
      <div class="expenses"
           v-show="isShow==='1'">
        <div class="content_left"
             :style="'background:'+getStorage('theme','')">
          <ul>
            <li @click="ybMoney('0')"
                :style="ybcost==='0'?'color:#ecf9f5':''">教育规划</li>
            <li @click="ybMoney('1')"
                :style="ybcost==='1'?'color:#ecf9f5':''">生活费用</li>
            <li @click="ybMoney('2')"
                :style="ybcost==='2'?'color:#ecf9f5':''">父母赡养</li>
            <li @click="ybMoney('3')"
                :style="ybcost==='3'?'color:#ecf9f5':''">住房费用</li>
            <li @click="ybMoney('4')"
                :style="ybcost==='4'?'color:#ecf9f5':''">教育费用</li>
            <li @click="ybMoney('5')"
                :style="ybcost==='5'?'color:#ecf9f5':''">其他贷款</li>
            <li @click="ybMoney('6')"
                :style="ybcost==='6'?'color:#ecf9f5':''">子女婚嫁</li>
            <li @click="ybMoney('7')"
                :style="ybcost==='7'?'color:#ecf9f5':''">医疗费用</li>
            <li @click="ybMoney('8')"
                :style="ybcost==='8'?'color:#ecf9f5':''">最后费用</li>
            <li @click="ybMoney('9')"
                :style="ybcost==='9'?'color:#ecf9f5':''">其他费用</li>
          </ul>
        </div>
        <div class="content_right">
          <!-- 规划期限 -->
          <div class="gh_time "
               v-show="ybcost==='0'">
            <h2>应备费用教育规划</h2>
            <div style="width: 100%;margin-top: 40px">
              <span>至子女教育结束</span>
              <md-radio-group v-model="JHQX[0].bbegages">
                <md-radio-box name="18">18岁</md-radio-box>
                <md-radio-box name="20">20岁</md-radio-box>
                <md-radio-box name="22">22岁</md-radio-box>
                <md-radio-box name="24">24岁</md-radio-box>
                <md-radio-box name="26">26岁</md-radio-box>
                <md-radio-box name="28">28岁</md-radio-box>
              </md-radio-group>
              <md-input-item v-model="JHQX[0].bbegages"
                             class="gh_time-input"
                             placeholder="请输入年龄">
              </md-input-item>
              <md-button :style="'background:'+getStorage('theme','')"
                         class="btn_bc"
                         size="large"
                         @click="fySave('YB','JHQX','1')"
                         :inactive="btnloading">保存</md-button>
            </div>
          </div>
          <!-- 生活费用模块 -->
          <div class="alimony"
               v-show="ybcost==='1'">
            <h2>应备每月生活费</h2>
            <div style="width: 100%;margin-top: 40px">
              <md-radio-group v-model="SHFY[0].bvalue">
                <md-radio-box name="2000">2000元</md-radio-box>
                <md-radio-box name="3000">3000元</md-radio-box>
                <md-radio-box name="3500">3500元</md-radio-box>
                <md-radio-box name="4000">4000元</md-radio-box>
                <md-radio-box name="5000">5000元</md-radio-box>
                <md-radio-box name="6000">6000元</md-radio-box>
                <md-radio-box name="7000">7000元</md-radio-box>
                <md-radio-box name="8000">8000元</md-radio-box>
                <md-radio-box name="9000">9000元</md-radio-box>
                <md-radio-box name="10000">10000元</md-radio-box>
                <md-radio-box name="12000">12000元</md-radio-box>
              </md-radio-group>
              <md-input-item v-model="SHFY[0].bvalue"
                             class="gh_time-input"
                             placeholder="请输入金额"></md-input-item>
              <md-button class="btn_bc"
                         :style="'background:'+getStorage('theme','')"
                         size="large"
                         :inactive="btnloading"
                         @click="fySave('YB','SHFY','1')">保存</md-button>
            </div>
          </div>
          <!-- 应备每月父母赡养费用模块 -->
          <div class="alimony"
               v-show="ybcost==='2'">
            <h2>应备每月父母赡养费用</h2>
            <div style="width: 100%">
              <md-tabs @change="changeTab"
                       immediate>
                <md-tab-pane class="cont"
                             name="syself"
                             label="本人">
                  <md-radio-group v-model="FMZY[0].bvalue">
                    <md-radio-box name="2000">2000元</md-radio-box>
                    <md-radio-box name="3000">3000元</md-radio-box>
                    <md-radio-box name="3500">3500元</md-radio-box>
                    <md-radio-box name="4000">4000元</md-radio-box>
                    <md-radio-box name="5000">5000元</md-radio-box>
                    <md-radio-box name="6000">6000元</md-radio-box>
                    <md-radio-box name="7000">7000元</md-radio-box>
                    <md-radio-box name="8000">8000元</md-radio-box>
                    <md-radio-box name="9000">9000元</md-radio-box>
                    <md-radio-box name="10000">10000元</md-radio-box>
                    <md-radio-box name="12000">12000元</md-radio-box>
                  </md-radio-group>
                  <md-input-item v-model="FMZY[0].bvalue"
                                 class="gh_time-input"
                                 placeholder="请输入金额"></md-input-item>
                  <md-button class="btn_bc"
                             size="large"
                             :style="'background:'+getStorage('theme','')"
                             :inactive="btnloading"
                             @click="fySave('YB','FMZY','1')">保存</md-button>
                </md-tab-pane>
                <md-tab-pane class="cont"
                             name="syspouse"
                             label="配偶">
                  <md-radio-group v-model="FMZY[0].pvalue">
                    <md-radio-box name="2000">2000元</md-radio-box>
                    <md-radio-box name="3000">3000元</md-radio-box>
                    <md-radio-box name="3500">3500元</md-radio-box>
                    <md-radio-box name="4000">4000元</md-radio-box>
                    <md-radio-box name="5000">5000元</md-radio-box>
                    <md-radio-box name="6000">6000元</md-radio-box>
                    <md-radio-box name="7000">7000元</md-radio-box>
                    <md-radio-box name="8000">8000元</md-radio-box>
                    <md-radio-box name="9000">9000元</md-radio-box>
                    <md-radio-box name="10000">10000元</md-radio-box>
                    <md-radio-box name="12000">12000元</md-radio-box>
                  </md-radio-group>
                  <md-input-item v-model="FMZY[0].pvalue"
                                 class="gh_time-input"
                                 placeholder="请输入金额"></md-input-item>
                  <md-button class="btn_bc"
                             :style="'background:'+getStorage('theme','')"
                             size="large"
                             :inactive="btnloading"
                             @click="fySave('YB','FMZY','0')">保存</md-button>
                </md-tab-pane>
              </md-tabs>
            </div>
          </div>
          <!--应备每月住房费用模块-->
          <div class="housing"
               v-show="ybcost==='3'">
            <h2>应备每月住房费用</h2>
            <md-tabs @change="changeTab"
                     immediate>
              <md-tab-pane class="cont"
                           name="zf"
                           label="租房">
                <div style="width: 100%;margin-top: 40px">
                  <span>每月房租（元/月）</span>
                  <md-radio-group v-model="house.fz">
                    <md-radio-box name="800">800元</md-radio-box>
                    <md-radio-box name="1000">1000元</md-radio-box>
                    <md-radio-box name="1500">1500元</md-radio-box>
                    <md-radio-box name="2000">2000元</md-radio-box>
                    <md-radio-box name="3000">3000元</md-radio-box>
                    <md-radio-box name="4000">4000元</md-radio-box>
                  </md-radio-group>
                  <md-input-item v-model="house.fz"
                                 class="gh_time-input"
                                 placeholder="请输入金额"></md-input-item>
                </div>
                <div style="width: 100%;margin-top: 40px">
                  <span>租房年限（年）</span>
                  <md-radio-group v-model="house.fzyars">
                    <md-radio-box name="1">1年</md-radio-box>
                    <md-radio-box name="2">2年</md-radio-box>
                    <md-radio-box name="3">3年</md-radio-box>
                    <md-radio-box name="4">4年</md-radio-box>
                    <md-radio-box name="5">5年</md-radio-box>
                    <md-radio-box name="6">6年</md-radio-box>
                  </md-radio-group>
                  <md-input-item v-model="house.fzyars"
                                 class="gh_time-input"
                                 placeholder="请输入时间"></md-input-item>
                </div>
                <md-button class="btn_bc"
                           :style="'background:'+getStorage('theme','')"
                           size="large"
                           :inactive="btnloading"
                           @click="fzSave('0')">保存</md-button>
              </md-tab-pane>
              <md-tab-pane class="cont selfsd "
                           name="zy"
                           label="自有">
                <span>商业贷款</span>
                <md-input-item v-model="house.sdmount"
                               title="贷款金额"
                               placeholder="请输入金额"
                               align="right"></md-input-item>
                <md-input-item v-model="house.sdyears"
                               title="还贷年限"
                               placeholder="请输入时间"
                               align="right"></md-input-item>
                <md-input-item v-model="house.yhsdyears"
                               title="已还年限"
                               placeholder="请输入时间"
                               align="right"></md-input-item>
                <md-input-item v-model="house.sdmon"
                               title="月还金额"
                               placeholder="请输入金额"
                               align="right"></md-input-item>
                <div style="margin-top: 40px">
                  <span>公积金贷款</span>
                  <md-input-item v-model="house.gjjmount"
                                 title="贷款金额"
                                 placeholder="请输入金额"
                                 align="right"></md-input-item>
                  <md-input-item v-model="house.gjjyears"
                                 title="还贷年限"
                                 placeholder="请输入时间"
                                 align="right"></md-input-item>
                  <md-input-item v-model="house.yhgjjyears"
                                 title="已还年限"
                                 placeholder="请输入时间"
                                 align="right"></md-input-item>
                  <md-input-item v-model="house.gjjmon"
                                 title="月还金额"
                                 placeholder="请输入金额"
                                 align="right"></md-input-item>
                </div>
                <md-button class="btn_bc"
                           :style="'background:'+getStorage('theme','')"
                           size="large"
                           :inactive="btnloading"
                           @click="fzSave('1')">保存</md-button>
              </md-tab-pane>
            </md-tabs>
          </div>
          <!-- 应备子女教育费用 -->
          <div class="znjy"
               v-show="ybcost==='4'">
            <h2>应备子女教育费用</h2>
            <div style="width: 100%;margin-top: 20px">
              <!-- 展示子女数量 -->
              <md-input-item v-model="znsum"
                             title="子女数"
                             readonly
                             align="right"></md-input-item>
              <education :abtComNbsEducation="abtComNbsEducation"></education>
            </div>
          </div>
          <!-- 其他贷款费用 -->
          <div class="qtdk"
               v-show="ybcost==='5'">
            <div class="jt_member otherdk">
              <span>其他贷款</span>
              <img src="@/assets/abt/img/meadd.png"
                   @click="dkadd">
            </div>
            <div>
              <md-tabs @change="changeTab"
                       immediate>
                <md-tab-pane name="dkself"
                             label="本人">
                  <div v-for="(item,index) in abtComNbsOtherdk"
                       :key="index">
                    <div class="jt_member person"
                         @click="dkSelfFold(index)">
                      <span>贷款项{{ index + 1}}</span>
                      <img src="@/assets/abt/img/jtright.png"
                           v-if="!dkself[index]">
                      <img src="@/assets/abt/img/jtdel.png"
                           v-if="dkself[index]"
                           @click.stop="dkdel(index,'0')">
                    </div>
                    <div v-show="dkself[index]">
                      <md-field>
                        <md-input-item v-model="abtComNbsOtherdk[index].xnname"
                                       title="项目名称"
                                       placeholder="请输入名称"
                                       align="right"></md-input-item>
                        <md-input-item v-model="abtComNbsOtherdk[index].bdkmount"
                                       title="贷款金额"
                                       placeholder="请输入金额"
                                       align="right"></md-input-item>
                        <md-input-item v-model="abtComNbsOtherdk[index].bdkyears"
                                       title="贷款年限"
                                       placeholder="请输入时间"
                                       align="right"></md-input-item>
                        <md-input-item v-model="abtComNbsOtherdk[index].byhyears"
                                       title="已还年限"
                                       placeholder="请输入时间"
                                       align="right"></md-input-item>
                        <md-input-item v-model="abtComNbsOtherdk[index].bmon"
                                       title="每月还款金额"
                                       placeholder="请输入金额"
                                       align="right"></md-input-item>
                      </md-field>
                    </div>
                  </div>
                  <md-button class="btn_bc"
                             :style="'background:'+getStorage('theme','')"
                             size="large"
                             :inactive="btnloading"
                             @click="otherdkSave('1')">保存</md-button>
                </md-tab-pane>
                <md-tab-pane class="cont"
                             name="dkspouse"
                             label="配偶">
                  <div v-for="(item,index) in abtComNbsOtherdk"
                       :key="index">
                    <div class="jt_member person"
                         @click="dkSpouseFold(index)">
                      <span>贷款项{{ index + 1}}</span>
                      <img src="@/assets/abt/img/jtright.png"
                           v-if="!dkspouse[index]">
                      <img src="@/assets/abt/img/jtdel.png"
                           v-if="dkspouse[index]"
                           @click.stop="dkdel(index,'1')">
                    </div>
                    <div v-show="dkspouse[index]">
                      <md-field>
                        <md-input-item v-model="abtComNbsOtherdk[index].xnname"
                                       title="项目名称"
                                       placeholder="请输入名称"
                                       align="right"></md-input-item>
                        <md-input-item v-model="abtComNbsOtherdk[index].pdkmount"
                                       title="贷款金额"
                                       placeholder="请输入金额"
                                       align="right"></md-input-item>
                        <md-input-item v-model="abtComNbsOtherdk[index].pdkyears"
                                       title="贷款年限"
                                       placeholder="请输入时间"
                                       align="right"></md-input-item>
                        <md-input-item v-model="abtComNbsOtherdk[index].pyhyears"
                                       title="已还年限"
                                       placeholder="请输入时间"
                                       align="right"></md-input-item>
                        <md-input-item v-model="abtComNbsOtherdk[index].pmon"
                                       title="每月还款金额"
                                       placeholder="请输入金额"
                                       align="right"></md-input-item>
                      </md-field>
                    </div>
                  </div>

                  <md-button class="btn_bc"
                             :style="'background:'+getStorage('theme','')"
                             size="large"
                             :inactive="btnloading"
                             @click="otherdkSave('0')">保存</md-button>
                </md-tab-pane>
              </md-tabs>
            </div>
          </div>
          <!-- 子女婚嫁费用模块 -->
          <div class="znhj"
               v-show="ybcost==='6'">
            <h2>子女婚嫁费用</h2>
            <marry :znMarri="znMarri"></marry>
          </div>
          <!--医疗费用模块-->
          <div class="ylfy"
               v-show="ybcost==='7'">
            <h2>医疗费用</h2>
            <md-field>
              <md-input-item v-for="(item,index) in YLFY"
                             :key="index"
                             v-model="item.bvalue"
                             :title="item.xmvalue.label"
                             placeholder="请输入"
                             :type="item.sxmid == 'BFDJ' ? 'digit' : 'text'"
                             align="right"></md-input-item>
            </md-field>
            <md-button class="btn_bc"
                       :style="'background:'+getStorage('theme','')"
                       size="large"
                       :inactive="btnloading"
                       @click="fySave('YB','YLFY','1')">保存</md-button>
          </div>
          <!-- 最后费用模块 -->
          <div class="zh_money"
               v-show="ybcost==='8'">
            <h2>最后费用</h2>
            <md-radio-group v-model="ZHFY[0].bvalue">
              <md-radio-box name="30000">30000</md-radio-box>
              <md-radio-box name="50000">50000</md-radio-box>
              <md-radio-box name="80000">80000</md-radio-box>
              <md-radio-box name="120000">120000</md-radio-box>
              <md-radio-box name="150000">150000</md-radio-box>
              <md-radio-box name="200000">200000</md-radio-box>
            </md-radio-group>
            <md-input-item v-model="ZHFY[0].bvalue"
                           class="gh_time-input"
                           placeholder="请输入金额"></md-input-item>
            <md-button class="btn_bc"
                       :style="'background:'+getStorage('theme','')"
                       size="large"
                       :inactive="btnloading"
                       @click="fySave('YB','ZHFY','1')">保存</md-button>
          </div>
          <!-- 其他费用模块 -->
          <div class="qt_money"
               v-show="ybcost==='9'">
            <h2>其他费用</h2>
            <div>
              <md-tabs @change="changeTab"
                       immediate>
                <md-tab-pane name="otherself"
                             label="本人">
                  <md-field>
                    <md-input-item v-model="QTFY[0].bvalue"
                                   title="费用金额"
                                   placeholder="请输入金额"
                                   align="right">
                      <div slot="right">元/ 年</div>
                    </md-input-item>
                    <md-input-item v-model="QTFY[0].bbegages"
                                   title="起始年龄"
                                   placeholder="请输入年龄"
                                   align="right">
                      <div slot="right">岁</div>
                    </md-input-item>
                    <md-input-item v-model="QTFY[0].bendages"
                                   title="结束年龄"
                                   placeholder="请输入年龄"
                                   align="right">
                      <div slot="right">岁</div>
                    </md-input-item>
                    <md-button class="btn_bc"
                               :style="'background:'+getStorage('theme','')"
                               size="large"
                               :inactive="btnloading"
                               @click="fySave('YB','QTFY','1')">保存</md-button>
                  </md-field>
                </md-tab-pane>
                <md-tab-pane name="otherspouse"
                             label="配偶">
                  <md-field>
                    <md-input-item v-model="QTFY[0].pvalue"
                                   title="每年费用金额"
                                   placeholder="请输入金额"
                                   align="right">
                      <div slot="right">元/ 年</div>
                    </md-input-item>
                    <md-input-item v-model="QTFY[0].pbegages"
                                   title="起始年龄"
                                   placeholder="请输入年龄"
                                   align="right">
                      <div slot="right">岁</div>
                    </md-input-item>
                    <md-input-item v-model="QTFY[0].pendages"
                                   title="结束年龄"
                                   placeholder="请输入年龄"
                                   align="right">
                      <div slot="right">岁</div>
                    </md-input-item>
                  </md-field>
                  <md-button :style="'background:'+getStorage('theme','')"
                             class="btn_bc"
                             size="large"
                             :inactive="btnloading"
                             @click="fySave('YB','QTFY','0')">保存</md-button>
                </md-tab-pane>
              </md-tabs>
            </div>
          </div>
        </div>
      </div>
      <!--已备费用模块-->
      <div class="forcost"
           v-show="isShow==='2'">
        <div class="content_left"
             :style="'background:'+getStorage('theme','')">
          <ul>
            <li @click="already('0')"
                :style="alreadyValue==='0'?'color:#ecf9f5':''">家庭收入</li>
            <li @click="already('1')"
                :style="alreadyValue==='1'?'color:#ecf9f5':''">家庭资产</li>
            <li @click="already('2')"
                :style="alreadyValue==='2'?'color:#ecf9f5':''">其他给付</li>
            <li @click="already('3')"
                :style="alreadyValue==='3'?'color:#ecf9f5':''">退休费用</li>
            <li @click="already('4')"
                :style="alreadyValue==='4'?'color:#ecf9f5':''">社保费用</li>
          </ul>
        </div>
        <div class="content_right">
          <!-- 家庭收入模块 -->
          <div class="familyincome"
               v-show="alreadyValue==='0'">
            <h2>家庭收入</h2>
            <div>
              <md-tabs @change="changeTab"
                       immediate>
                <md-tab-pane name="srself"
                             label="本人">
                  <md-field>
                    <span>年收入</span>
                    <md-input-item v-model="JTSR[0].bvalue"
                                   title="收入金额"
                                   placeholder="请输入金额"
                                   align="right">
                      <div slot="right">元/ 年</div>
                    </md-input-item>
                    <md-input-item title="结束年龄"
                                   v-model="JTSR[0].bendages"
                                   placeholder="请输入年龄"
                                   align="right">
                      <div slot="right">岁</div>
                    </md-input-item>
                    <span style="margin-top: 40px">其他收入</span>
                    <md-input-item v-model="JTSR[1].bvalue"
                                   title="收入金额"
                                   placeholder="请输入金额"
                                   align="right">
                      <div slot="right">元/月</div>
                    </md-input-item>
                    <md-input-item v-model="JTSR[1].bbegages"
                                   title="起始年龄"
                                   placeholder="请输入年龄"
                                   align="right">
                      <div slot="right">岁</div>
                    </md-input-item>
                    <md-input-item v-model="JTSR[1].bendages"
                                   title="结束年龄"
                                   placeholder="请输入年龄"
                                   align="right">
                      <div slot="right">岁</div>
                    </md-input-item>
                    <md-button :style="'background:'+getStorage('theme','')"
                               class="btn_bc"
                               size="large"
                               :inactive="btnloading"
                               @click="fySave('OB','JTSR','1')">保存</md-button>
                  </md-field>
                </md-tab-pane>
                <md-tab-pane name="srspouse"
                             label="配偶">
                  <md-field>
                    <span>年收入</span>
                    <md-input-item v-model="JTSR[0].pvalue"
                                   title="收入金额"
                                   placeholder="请输入金额"
                                   align="right">
                      <div slot="right">元/ 年</div>
                    </md-input-item>
                    <md-input-item v-model="JTSR[0].pendages"
                                   title="结束年龄"
                                   placeholder="请输入年龄"
                                   align="right">
                      <div slot="right">岁</div>
                    </md-input-item>
                    <span style="margin-top: 40px">其他收入</span>
                    <md-input-item v-model="JTSR[1].pvalue"
                                   title="收入金额"
                                   placeholder="请输入金额"
                                   align="right">
                      <div slot="right">元/ 月</div>
                    </md-input-item>
                    <md-input-item v-model="JTSR[1].pbegages"
                                   title="起始年龄"
                                   placeholder="请输入年龄"
                                   align="right">
                      <div slot="right">岁</div>
                    </md-input-item>
                    <md-input-item v-model="JTSR[1].pendages"
                                   title="结束年龄"
                                   placeholder="请输入年龄"
                                   align="right">
                      <div slot="right">岁</div>
                    </md-input-item>
                  </md-field>
                  <md-button :style="'background:'+getStorage('theme','')"
                             class="btn_bc"
                             size="large"
                             :inactive="btnloading"
                             @click="fySave('OB','JTSR','0')">保存</md-button>
                </md-tab-pane>
              </md-tabs>
            </div>
          </div>
          <!-- 家庭资产模块 -->
          <div class="familyassets"
               v-show="alreadyValue==='1'">
            <h2>家庭资产</h2>
            <div>
              <md-tabs @change="changeTab"
                       immediate>
                <md-tab-pane name="zcself"
                             label="本人">
                  <md-field>
                    <md-input-item v-model="familyzc[0].bvalue"
                                   title="储蓄"
                                   placeholder="请输入金额"
                                   align="right">
                      <div slot="right">元</div>
                    </md-input-item>
                    <md-input-item v-model="familyzc[1].bvalue"
                                   title="房产"
                                   placeholder="请输入金额"
                                   align="right">
                      <div slot="right">元</div>
                    </md-input-item>
                    <md-input-item v-model="familyzc[2].bvalue"
                                   title="理财"
                                   placeholder="请输入金额"
                                   align="right">
                      <div slot="right">元</div>
                    </md-input-item>
                    <md-input-item v-model="familyzc[3].bvalue"
                                   title="股票"
                                   placeholder="请输入金额"
                                   align="right">
                      <div slot="right">元</div>
                    </md-input-item>
                    <md-input-item v-model="familyzc[4].bvalue"
                                   title="基金"
                                   placeholder="请输入金额"
                                   align="right">
                      <div slot="right">元</div>
                    </md-input-item>
                    <md-input-item v-model="familyzc[5].bvalue"
                                   title="其他"
                                   placeholder="请输入金额"
                                   align="right">
                      <div slot="right">元</div>
                    </md-input-item>
                  </md-field>
                  <md-button class="btn_bc"
                             :style="'background:'+getStorage('theme','')"
                             size="large"
                             :inactive="btnloading"
                             @click="fySave('OB','JTZC','1')">保存</md-button>
                </md-tab-pane>
                <md-tab-pane name="zcspouse"
                             label="配偶">
                  <md-field>
                    <md-input-item v-model="familyzc[0].pvalue"
                                   title="储蓄"
                                   placeholder="请输入金额"
                                   align="right">
                      <div slot="right">元</div>
                    </md-input-item>
                    <md-input-item v-model="familyzc[1].pvalue"
                                   title="房产"
                                   placeholder="请输入金额"
                                   align="right">
                      <div slot="right">元</div>
                    </md-input-item>
                    <md-input-item v-model="familyzc[2].pvalue"
                                   title="理财"
                                   placeholder="请输入金额"
                                   align="right">
                      <div slot="right">元</div>
                    </md-input-item>
                    <md-input-item v-model="familyzc[3].pvalue"
                                   title="股票"
                                   placeholder="请输入金额"
                                   align="right">
                      <div slot="right">元</div>
                    </md-input-item>
                    <md-input-item v-model="familyzc[4].pvalue"
                                   title="基金"
                                   placeholder="请输入金额"
                                   align="right">
                      <div slot="right">元</div>
                    </md-input-item>
                    <md-input-item v-model="familyzc[5].pvalue"
                                   title="其他"
                                   placeholder="请输入金额"
                                   align="right">
                      <div slot="right">元</div>
                    </md-input-item>
                  </md-field>
                  <md-button class="btn_bc"
                             :style="'background:'+getStorage('theme','')"
                             size="large"
                             :inactive="btnloading"
                             @click="fySave('OB','JTZC','0')">保存</md-button>
                </md-tab-pane>
              </md-tabs>
            </div>
          </div>
          <!-- 其他给付 -->
          <div class="otherpayment"
               v-show="alreadyValue==='2'">
            <h2>其他给付</h2>
            <div>
              <md-tabs @change="changeTab"
                       immediate>
                <md-tab-pane name="p1"
                             label="本人">
                  <md-field>
                    <md-input-item v-model="QTGF[0].bvalue"
                                   title="遗嘱津贴"
                                   placeholder="请输入金额"
                                   align="right">
                      <div slot="right">元/ 月</div>
                    </md-input-item>
                    <md-input-item v-model="QTGF[1].bvalue"
                                   title="公司抚恤金"
                                   placeholder="请输入金额"
                                   align="right">
                      <div slot="right">元/ 月</div>
                    </md-input-item>
                  </md-field>
                  <md-button class="btn_bc"
                             :style="'background:'+getStorage('theme','')"
                             size="large"
                             :inactive="btnloading"
                             @click="fySave('OB','QTGF','1')">保存</md-button>
                </md-tab-pane>
                <md-tab-pane class="cont"
                             name="p2"
                             label="配偶">
                  <md-field>
                    <md-input-item v-model="QTGF[0].pvalue"
                                   title="遗嘱津贴"
                                   placeholder="请输入金额"
                                   align="right">
                      <div slot="right">元/ 月</div>
                    </md-input-item>
                    <md-input-item v-model="QTGF[1].pvalue"
                                   title="公司抚恤金"
                                   placeholder="请输入金额"
                                   align="right">
                      <div slot="right">元/ 月</div>
                    </md-input-item>
                  </md-field>
                  <md-button class="btn_bc"
                             :style="'background:'+getStorage('theme','')"
                             size="large"
                             :inactive="btnloading"
                             @click="fySave('OB','QTGF','0')">保存</md-button>
                </md-tab-pane>
              </md-tabs>
            </div>
          </div>
          <!-- 退休金模块 -->
          <div class="pension"
               v-show="alreadyValue==='3'">
            <h2>退休费用</h2>
            <div>
              <md-tabs @change="changeTab"
                       immediate>
                <md-tab-pane name="txself"
                             label="本人">
                  <md-field>
                    <md-input-item v-model="TXFY[0].bvalue"
                                   title="月退休金"
                                   placeholder="请输入金额"
                                   align="right">
                      <div slot="right">元/ 月</div>
                    </md-input-item>
                    <md-input-item v-model="TXFY[0].bbegages"
                                   title="起始年龄"
                                   placeholder="请输入年龄"
                                   align="right">
                      <div slot="right">岁</div>
                    </md-input-item>
                    <md-input-item v-model="TXFY[0].bendages"
                                   title="终止年龄"
                                   placeholder="请输入年龄"
                                   align="right">
                      <div slot="right">岁</div>
                    </md-input-item>
                  </md-field>
                  <md-button class="btn_bc"
                             size="large"
                             :style="'background:'+getStorage('theme','')"
                             :inactive="btnloading"
                             @click="fySave('OB','TXFY','1')">保存</md-button>
                </md-tab-pane>
                <md-tab-pane class="cont"
                             name="txspouse"
                             label="配偶">
                  <md-field>
                    <md-input-item v-model="TXFY[0].pvalue"
                                   title="月退休金"
                                   placeholder="请输入金额"
                                   align="right">
                      <div slot="right">元/ 月</div>
                    </md-input-item>
                    <md-input-item v-model="TXFY[0].pbegages"
                                   title="起始年龄"
                                   placeholder="请输入年龄"
                                   align="right">
                      <div slot="right">岁</div>
                    </md-input-item>
                    <md-input-item v-model="TXFY[0].pendages"
                                   title="终止年龄"
                                   placeholder="请输入年龄"
                                   align="right">
                      <div slot="right">岁</div>
                    </md-input-item>
                  </md-field>
                  <md-button class="btn_bc"
                             :style="'background:'+getStorage('theme','')"
                             size="large"
                             :inactive="btnloading"
                             @click="fySave('OB','TXFY','1')">保存</md-button>
                </md-tab-pane>
              </md-tabs>
            </div>
          </div>
          <!-- 社保费用模块 -->
          <div class="societymoney"
               v-show="alreadyValue==='4'">
            <h2>社保费用</h2>
            <div>
              <md-tabs @change="changeTab"
                       immediate>
                <md-tab-pane name="sbself"
                             label="本人">
                  <md-field>
                    <md-input-item v-model="SBFY[0].bvalue"
                                   title="社会养老"
                                   placeholder="请输入金额"
                                   align="right">
                      <div slot="right">元/ 月</div>
                    </md-input-item>
                    <md-input-item v-model="SBFY[1].bvalue"
                                   title="商业养老"
                                   placeholder="请输入金额"
                                   align="right">
                      <div slot="right">元/ 月</div>
                    </md-input-item>
                    <md-input-item v-model="SBFY[2].bvalue"
                                   title="公司养老"
                                   placeholder="请输入金额"
                                   align="right">
                      <div slot="right">元/ 月</div>
                    </md-input-item>
                  </md-field>
                  <md-button class="btn_bc"
                             :style="'background:'+getStorage('theme','')"
                             size="large"
                             :inactive="btnloading"
                             @click="fySave('OB','SBFY','1')">保存</md-button>
                </md-tab-pane>
                <md-tab-pane class="cont"
                             name="sbspouse"
                             label="配偶">
                  <md-field>
                    <md-input-item v-model="SBFY[0].pvalue"
                                   title="社会养老"
                                   placeholder="请输入金额"
                                   align="right">
                      <div slot="right">元/ 月</div>
                    </md-input-item>
                    <md-input-item v-model="SBFY[1].pvalue"
                                   title="商业养老"
                                   placeholder="请输入金额"
                                   align="right">
                      <div slot="right">元/ 月</div>
                    </md-input-item>
                    <md-input-item v-model="SBFY[2].pvalue"
                                   title="公司养老"
                                   placeholder="请输入金额"
                                   align="right">
                      <div slot="right">元/ 月</div>
                    </md-input-item>
                  </md-field>
                  <md-button :style="'background:'+getStorage('theme','')"
                             class="btn_bc"
                             size="large"
                             :inactive="btnloading"
                             @click="fySave('OB','SBFY','0')">保存</md-button>
                </md-tab-pane>
              </md-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div :class="personkey==='1'?'navsetting1':'navsetting'">
       <div class="backbtn" @click="backtopage" :style="'height:'+(personkey==='1'?'100%':'50%')">
        <span class="iconfont iconback"></span>
       </div>
       <div class="backtohome" @click="backtohomepage" v-if="personkey === '0'">
        <span class="iconfont iconhome"></span>
       </div>
    </div> -->
  </div>
</template>

<script>
import { Tabs, Icon, CellItem, InputItem, RadioBox, RadioGroup, Toast } from 'mand-mobile'
import { selectMJ } from '@/api/common'
import { menberAdd, addOtherdk, addhouse, showfamilyInfo, addFamilyv, deleteOther } from '@/api/nbs/nbsinfo/index'
import education from './education'
import marry from './marry'
import familyinfo from './familyinfo'
import familymember from './familymember'
import { getStorage } from '@/lib/util'
export default {
  components: {
    [Tabs.name]: Tabs,
    [Icon.name]: Icon,
    [CellItem.name]: CellItem,
    [InputItem.name]: InputItem,
    [RadioBox.name]: RadioBox,
    [RadioGroup.name]: RadioGroup,
    [Toast.name]: Toast,
    marry,
    education,
    familyinfo,
    familymember
  },
  data () {
    return {
      personkey: '',
      comid: this.$route.query.comid,
      empno: this.$route.query.empno,
      insurants: '',
      userinfo: {},
      familyno: '',
      znsum: '0',
      minDate: new Date('1900/01/01'),
      defaultdate: new Date('2000/01/01'),
      isShow: '0', // 顶部模块显示判断
      selfdate: '', // 出生日期赋值
      spousedate: '', // 出生日期赋值
      isDatePickerShow: false,
      DatespouseShow: false,
      selectorValue: '',
      spousecard: '',
      // isSelectorShow: false, // 证件类型下拉是否显示
      familyvalue: '0', // 判断家庭资料显示
      ybcost: '0', // 应备费用模块显示
      alreadyValue: '0', // 已备费用模块显示
      educationOverAge: '18', // 子女教育结束年龄
      selfzd: false, // 本人展示折叠控制
      spouse: false, // 配偶展示折叠控制
      ryOptions: [], // 人员关系
      credtype: [], // 证件类型
      jtcy: [], // 家庭成员数组
      znMarri: [],
      abtComNbsEducation: [],
      familyzd: [], // 家庭成员折叠
      clist: [],
      height: '',
      tyselect: '',
      isRelation: [false], // 关系弹窗
      RelationValue: [], // 关系显示的值
      ismeDate: [false], // 控制选择时间弹窗
      ismeDateValue: [], // 家庭成员出生日期的值
      rytypelist: [false],
      rytypeValue: [],
      dkself: [true],
      dkspouse: [true],
      selfsex: '2',
      spousesex: '1',
      manwife: [
        {
          familyno: this.$route.query.familyno,
          name: '',
          relation: '00',
          cardtype: '',
          cardno: '',
          sex: '2',
          birthdate: '',
          zhiye: ''
        }, {
          familyno: this.$route.query.familyno,
          name: '',
          relation: '02',
          cardtype: '',
          cardno: '',
          sex: '1',
          birthdate: '',
          zhiye: ''
        }
      ],
      abtComNbsOtherdk: [
        {
          xnname: '',
          bdkmount: '',
          bdkyears: '',
          byhyears: '',
          bmon: '',
          pdkmount: '',
          pdkyears: '',
          pyhyears: '',
          pmon: ''
        }
      ],
      house: {
        familyno: '',
        fz: '800', // 每月房租应备费用
        fzyars: '1', // 租房年限
        sdmount: '',
        sdyears: '',
        yhsdyears: '',
        sdmon: '',
        gjjmount: '',
        gjjyears: '',
        yhgjjyears: '',
        gjjmon: ''
      },
      //  家庭资产
      familyzc: [
        {          sxmid: 'CX',
          bvalue: '',
          pvalue: ''
        },
        {
          sxmid: 'FC',
          bvalue: '',
          pvalue: ''
        },
        {          sxmid: 'LC',
          bvalue: '',
          pvalue: ''
        },
        {          sxmid: 'GP',
          bvalue: '',
          pvalue: ''
        },
        {          sxmid: 'JJ',
          bvalue: '',
          pvalue: ''
        },
        {          sxmid: 'QT',
          bvalue: '',
          pvalue: ''
        }
      ],
      // 家庭资产
      JTSR: [
        {
          sxmid: 'NSR',
          bvalue: '',
          pvalue: '',
          bendages: '',
          pendages: ''
        },
        {
          sxmid: 'QTSR',
          bvalue: '',
          pvalue: '',
          bbegages: '',
          bendages: '',
          pbegages: '',
          pendages: ''
        }
      ],
      // 其他给付
      QTGF: [
        {
          sxmid: 'YZJT',
          bvalue: '',
          pvalue: ''
        },
        {
          sxmid: 'FXJ',
          bvalue: '',
          pvalue: ''
        }
      ],
      // 退休费用
      TXFY: [
        {
          sxmid: '',
          bvalue: '',
          pvalue: '',
          bbegages: '',
          bendages: '',
          pbegages: '',
          pendages: ''
        }
      ],
      // 社保费用
      SBFY: [
        {
          sxmid: 'SHYL',
          bvalue: '',
          pvalue: ''
        },
        {
          sxmid: 'SYYL',
          bvalue: '',
          pvalue: ''
        },
        {
          sxmid: 'GSTXJ',
          bvalue: '',
          pvalue: ''
        }
      ],
      // 父母赡养费用
      FMZY: [
        {
          sxmid: '',
          bvalue: '2000',
          pvalue: '2000'
        }
      ],
      // 其他费用
      QTFY: [
        {
          sxmid: '',
          bvalue: '',
          bbegages: '',
          bendages: '',
          pvalue: '',
          pbegages: '',
          pendages: ''
        }
      ],
      // 最后费用
      ZHFY: [
        {
          sxmid: '',
          bvalue: '30000',
          pvalue: '30000'
        }
      ],
      // 规划期限
      JHQX: [
        {
          sxmid: '',
          bbegages: '18'
        }
      ],
      // 生活费用
      SHFY: [
        {
          sxmid: '',
          bvalue: '2000'
        }
      ],
      // 医疗费用
      YLFY: [
        {
          sxmid: 'BFDJ',
          bvalue: '',
          xmvalue: {
            label: '病房等级'
          }
        },
        {
          sxmid: 'HLF',
          bvalue: '',
          xmvalue: {
            label: '护理费'
          }
        },
        {
          sxmid: 'SRSS',
          bvalue: '',
          xmvalue: {
            label: '收入损失'
          }
        },
        {
          sxmid: 'YLF',
          bvalue: '',
          xmvalue: {
            label: '医疗费'
          }
        }
      ],
      btnloading: false
    }
  },
  created () {
    this.personkey = this.$route.query.personkey
    this.height = document.documentElement.clientHeight
    this.getSelectMJ()
    this.familyno = this.$route.query.familyno
    this.getfamily()
  },
  mounted () {
    this.$nextTick(() => {
      this.changeTab()
      // temp.style.cssText = `background-color:${getStorage('theme','')} !important`
      // te.style.cssText = `color:${getStorage('theme','')} !important`
      // tem.style.cssText = `color:${getStorage('theme','')} !important`
    })
  },
  updated () {
    this.changeTab()
  },
  watch: {
    'znMarri' () {
      this.znsum = this.znMarri.length
    }
  },
  methods: {
    // 编辑信息回显
    getfamily () {
      let num = 0
      let Education = '1'
      let marry = '1'
      showfamilyInfo({ familyno: this.familyno }).then(res => {
        let Data = res.data.data
        // 住房费用回显
        if (Data.abtComNbsHouse !== null) {
          this.house = Data.abtComNbsHouse
          this.house.fz = String(this.house.fz)
          this.house.fzyars = String(this.house.fzyars)
        }
        // 教育费用回显
        if (Data.abtComNbsEducationList.length !== 0) {
          this.abtComNbsEducation = Data.abtComNbsEducationList
        }
        // 其他贷款回显
        if (Data.abtComNbsOtherdkList.length !== 0) {
          this.abtComNbsOtherdk = Data.abtComNbsOtherdkList
        }
        // 子女婚嫁回显
        if (Data.znmarryList.length !== 0) {
          this.znMarri = Data.znmarryList
        }
        // 家庭成员信息回显
        if (Data.familymember.length !== 0) {
          Data.familymember.forEach(item => {
            if (item.relationvalue.value === '00') {
              this.$refs.familyinfo.selfdate = item.birthdate
              this.$refs.familyinfo.selfsex = item.sex
              this.$refs.familyinfo.selectorValue = item.idcardvalue.label
              this.manwife[0] = item
            } else if (item.relationvalue.value === '02') {
              this.$refs.familyinfo.spousesex = item.sex
              this.manwife[1] = item
              this.$refs.familyinfo.spousedate = item.birthdate
              this.$refs.familyinfo.spousecard = item.idcardvalue.label
            } else if (item.relationvalue.value !== '00' && item.relationvalue.value !== '02') {
              item.oldname = item.name
              this.jtcy.push(item)
              this.familyzd.push(false)
              this.RelationValue[num] = item.relationvalue.label
              this.ismeDateValue[num] = item.birthdate
              this.rytypeValue[num] = item.idcardvalue.label
              num++
            }
          })
        }
        this.jtcy.forEach(item => {
          if (item.relation === '03') {
            Education = '1'
            marry = '1'
            this.abtComNbsEducation.some(from => {
              if (item.name === from.name) {
                Education = '0'
                return true
              }
            })
            this.znMarri.some(i => {
              if (item.name === i.name) {
                marry = '0'
                return true
              }
            })
            if (Education === '1') {
              this.abtComNbsEducation.push({
                familyno: this.familyno,
                name: item.name,
                playschool: '',
                primaryschool: '',
                midschool: '',
                highschool: '',
                university: '',
                graduate: '',
                doctor: ''
              })
            }
            if (marry === '1') {
              this.znMarri.push({
                familyno: this.familyno,
                name: item.name,
                yjmoney: '',
                yjages: ''
              })
            }
          }
        })
        // 家庭资产
        if (Data.familyValueList.OB.JTZC.length !== 0) {
          Data.familyValueList.OB.JTZC.forEach(item => {
            if (item.sxmid === 'CX') {
              this.familyzc[0] = item
            } else if (item.sxmid === 'FC') {
              this.familyzc[1] = item
            } else if (item.sxmid === 'LC') {
              this.familyzc[2] = item
            } else if (item.sxmid === 'GB') {
              this.familyzc[3] = item
            } else if (item.sxmid === 'JJ') {
              this.familyzc[4] = item
            } else if (item.sxmid === 'QT') {
              this.familyzc[5] = item
            }
          })
        }
        // 家庭收入
        if (Data.familyValueList.OB.JTSR.length !== 0) {
          this.JTSR = Data.familyValueList.OB.JTSR
        }
        // 其他给付
        if (Data.familyValueList.OB.QTGF.length !== 0) {
          Data.familyValueList.OB.QTGF.forEach(item => {
            if (item.sxmid === 'YZJT') {
              this.QTGF[0] = item
            }
            if (item.sxmid === 'FXJ') {
              this.QTGF[1] = item
            }
          })
        }
        // 退休费用
        if (Data.familyValueList.OB.TXFY.length !== 0) {
          this.TXFY = Data.familyValueList.OB.TXFY
        }
        // 社保费用
        if (Data.familyValueList.OB.SBFY.length !== 0) {
          Data.familyValueList.OB.SBFY.forEach(item => {
            if (item.sxmid === 'SHYL') {
              this.SBFY[0] = item
            } else if (item.sxmid === 'SYYL') {
              this.SBFY[1] = item
            } else if (item.sxmid === 'GSTXJ') {
              this.SBFY[2] = item
            }
          })
        }
        if (Data.familyValueList.YB.FMZY.length !== 0) {
          this.FMZY = Data.familyValueList.YB.FMZY
          this.FMZY[0].bvalue = String(this.FMZY[0].bvalue)
          this.FMZY[0].pvalue = String(this.FMZY[0].pvalue)
        }
        if (Data.familyValueList.YB.QTFY.length !== 0) {
          this.QTFY = Data.familyValueList.YB.QTFY
        }
        if (Data.familyValueList.YB.ZHFY.length !== 0) {
          this.ZHFY = Data.familyValueList.YB.ZHFY
          this.ZHFY[0].bvalue = String(this.ZHFY[0].bvalue)
          this.ZHFY[0].pvalue = String(this.ZHFY[0].pvalue)
        }
        if (Data.familyValueList.YB.JHQX.length !== 0) {
          this.JHQX = Data.familyValueList.YB.JHQX
          this.JHQX[0].bbegages = String(this.JHQX[0].bbegages)
        }
        if (Data.familyValueList.YB.SHFY.length !== 0) {
          this.SHFY = Data.familyValueList.YB.SHFY
          this.SHFY[0].bvalue = String(this.SHFY[0].bvalue)
        }
        if (Data.familyValueList.YB.YLFY.length !== 0) {
          this.YLFY = Data.familyValueList.YB.YLFY
        }
        this.$forceUpdate()
      })
    },
    getempty () {
      this.abtComNbsEducation = []
      this.znMarri = []
      this.jtcy = []
      this.$forceUpdate()
    },
    // 获取枚举信息
    getSelectMJ () {
      selectMJ({ list: ['YWSHIP', 'BBCARD'] }).then(res => {
        let Data = res.data.data
        for (let item of Data) {
          if (item.label === 'YWSHIP') {
            this.ryOptions = item.value
            this.ryOptions.forEach(item => {
              item.value = item.code
              item.text = item.name
            })
          } else if (item.label === 'BBCARD') {
            this.credtype = item.value
            this.credtype.forEach(item => {
              item.value = item.code
              item.text = item.name
            })
          }
        }
      })
    },
    // 其他贷款项保存
    otherdkSave (val) {
      this.btnloading = true
      let data = {
        bpcheck: val,
        familyno: this.familyno,
        abtComNbsOtherdk: this.abtComNbsOtherdk
      }
      addOtherdk(data).then(res => {
        Toast.info('保存成功', 1000)
        this.getempty()
        this.getfamily()
      }).finally(() => {
        this.btnloading = false
      })
    },
    // 房租项保存
    fzSave (type) {
      this.btnloading = true
      this.house.familyno = this.familyno
      let data = {
        house: this.house,
        isZf: type
      }
      addhouse(data).then(res => {
        Toast.info('保存成功', 1000)
        this.getempty()
        this.getfamily()
      }).finally(() => {
        this.btnloading = false
      })
    },
    // 费用项保存
    fySave (dltype, xltype, stype) {
      this.btnloading = true
      let data = {
        familyno: this.familyno,
        xmtype: dltype,
        bxmid: xltype,
        bpcheck: stype,
        abtComNbsFamilyValue: []
      }
      // 家庭资产
      if (xltype === 'JTZC') {
        data.abtComNbsFamilyValue = this.familyzc
      } else if (xltype === 'JTSR') { // 家庭收入
        data.abtComNbsFamilyValue = this.JTSR
      } else if (xltype === 'QTGF') { // 其他给付
        data.abtComNbsFamilyValue = this.QTGF
      } else if (xltype === 'TXFY') { // 退休费用
        data.abtComNbsFamilyValue = this.TXFY
      } else if (xltype === 'SBFY') { // 社保费用
        data.abtComNbsFamilyValue = this.SBFY
      } else if (xltype === 'FMZY') { // 父母赡养
        data.abtComNbsFamilyValue = this.FMZY
      } else if (xltype === 'QTFY') { // 其他费用
        data.abtComNbsFamilyValue = this.QTFY
      } else if (xltype === 'ZHFY') { // 最后费用
        data.abtComNbsFamilyValue = this.ZHFY
      } else if (xltype === 'JHQX') { // 规划期限
        data.abtComNbsFamilyValue = this.JHQX
      } else if (xltype === 'SHFY') { // 生活费用
        data.abtComNbsFamilyValue = this.SHFY
      } else if (xltype === 'YLFY') { // 医疗费用
        data.abtComNbsFamilyValue = this.YLFY
      }
      addFamilyv(data).then(res => {
        Toast.info('保存成功', 1000)
        this.getempty()
        this.getfamily()
      }).finally(() => {
        this.btnloading = false
      })
    },
    // 成员添加
    cyadd () {
      this.jtcy.push({
        familyno: this.$route.query.familyno,
        name: '',
        relation: '',
        cardtype: '',
        cardno: '',
        sex: '2',
        birthdate: '',
        mobile: '',
        zhiye: ''
      })
      this.familyzd.push(true)
    },
    // 贷款项添加
    dkadd () {
      this.abtComNbsOtherdk.push({
        xnname: '',
        bdkmount: '',
        bdkyears: '',
        byhyears: '',
        bmon: '',
        pdkmount: '',
        pdkyears: '',
        pyhyears: '',
        pmon: ''
      })
      this.dkself.push(true)
      this.dkspouse.push(true)
    },
    // 人员时间弹窗开关控制
    meDateShow (index) {
      this.ismeDate[index] = true
      this.$forceUpdate()
    },
    dkdel (index, type) {
      // this.abtComNbsOtherdk.splice(index, 1)
      this.dkself.splice(index, 1)
      this.dkspouse.splice(index, 1)
      let data = {
        empno: this.empno,
        familyno: this.$route.query.familyno,
        xnname: this.abtComNbsOtherdk[index].xnname,
        othercheck: type
      }
      deleteOther(data).then(res => {
        Toast.info('删除成功', 1000)
        this.getempty()
        this.getfamily()
      })
    },
    dkSelfFold (index) {
      this.dkself[index] = !this.dkself[index]
      this.$forceUpdate()
    },
    dkSpouseFold (index) {
      this.dkspouse[index] = !this.dkspouse[index]
      this.$forceUpdate()
    },
    // 控制顶部模块显示
    topswitch (show) {
      this.isShow = show
    },
    // 家庭信息模块显示
    familyqh (val) {
      this.familyvalue = val
    },
    // 应备费用下模块显示
    ybMoney (val) {
      this.ybcost = val
    },
    // 已备费用下模块显示
    already (val) {
      this.alreadyValue = val
    },
    // 查看报告
    toShare () {
      this.$router.push(
        {          path: '/nbsReport',
          query: {            familyname: this.$route.query.familyname,
            familyno: this.familyno,
            comid: this.comid,
            empno: this.empno
          }        })
    },
    backtopage () {
      this.$router.push({        path: '/nbsReport',
        query: {
          familyname: this.$route.query.familyname,
          familyno: this.$route.query.familyno,
          fromwhere: this.$route.query.fromwhere,
          comid: this.$route.query.comid,
          empno: this.$route.query.empno,
          custom: '1',
          personkey: this.$route.query.personkey
        }      })
    },
    backtohomepage () { this.$router.push({ path: '/bdtg', query: { fromwhere: this.$route.query.fromwhere, bdtgflush: true } }) },
    changeTab () {
      let temp = document.getElementsByClassName('md-tab-bar-ink')
      let tem = document.getElementsByClassName('md-tab-bar-item')
      let te = document.getElementsByClassName('is-active')
      Array.from(temp).forEach(ele => {
        ele.style.cssText = `background-color:${getStorage('theme', '')} !important`
      })
      Array.from(tem).forEach(ele => {
        ele.style.cssText = `color:${getStorage('theme', '')} !important`
      })
      Array.from(te).forEach(ele => {
        ele.style.cssText = `color:${getStorage('theme', '')} !important`
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.container {
  widh: 100%;
  height: 100%;
  position: relative;
}

.content {
  margin-top: 160px;
}

.top {
  width: 100%;
  height: 180px;
  // background-image: url('~@/assets/abt/img/topbj.png');
  background-color: color-primary;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
}

.top li img {
  width: 80px;
}

.top p {
  font-size: 40px;
}

ul {
  color: #c6c5be;
}

li {
  font-size: 40px !important;
}

.formation, .forcost {
  display: flex;
  height: 100%;
}

.expenses {
  display: flex;
  /* height: 100%; */
}

.top ul {
  display: flex;
  justify-content: center;
}

.top li {
  margin-top: 20px;
  padding: 0 70px;
}

.content_left {
  width: 13%;
  padding-top: 8%;
  background-color: color-primary;
  overflow-y: auto;
  height: 150vh;
}

.content_right {
  padding-top: 8%;
  margin: 0 5%;
  width: 77%;
  overflow-y: auto;
  height: 150vh;
}

.content_left li {
  width: 90%;
  padding: 30px 0px 30px 15px;
}

.content_left ul li:nth-child(1) {
  padding-top: 1rem;
}

.md-example-child-tabs, .cont {
  padding: 12px 0;
  font-size: 28px;
  background: #FFF;
  padding: 20px;
  line-height: 1.5;
  box-sizing: border-box;
}

.md-tabs-content {
  min-height: 200px;
  background: #FFF;
}

.md-scroll-view .scroll-view-container.horizon {
  display: none;
}

.jt_title {
  font-weight: bold;
}

.expenses /deep/ .md-check-base-box {
  width: 170px;
  margin: 10px 20px;
  font-size: 32px;
}

.expenses /deep/ .md-radio-group {
  margin-left: 80px;
  margin-top: 80px;
}

.expenses .gh_time-input {
  width: 400px;
  margin-left: 100px;
  /* background: #eee; */
}

/deep/ .md-input-item-input, .md-input-item-fake {
  font-size: 34px;
  min-width: 80%;
}

.formation {
  /deep/ .md-input-item-input {
    width: auto;
  }
}

.gh_time-input /deep/ .md-input-item-input {
  background-color: #eee;
}

.btn_bc {
  margin-top: 1rem;
  color: #FFF;
  background-color: color-primary;
}

/deep/ .md-button.default {
  background: color-primary;
}

/deep/ .md-button {
  background: color-primary;
}

.jt_member /deep/ span {
  font-size: 0.4rem;
}

.fa_member .person {
  margin-top: 50px;
}

.jt_member img {
  vertical-align: middle;
  float: right;
  width: 40px;
  margin-top: 3px;
}

.formation /deep/ .md-check-base-box {
  width: 120px;
  margin: 0px 20px;
}

.formation /deep/ .md-radio-group {
  margin-left: 160px;
}

/deep/ fieldset {
  font-size: 40px;
}

.md-field-item.is-solid /deep/ .md-field-item-title {
  width: 2.2rem;
  font-size: 34px;
}

.otherdk {
  font-weight: bold;
}

.person {
  margin-top: 30px;
}

/deep/ .md-field-item-content {
  padding-bottom: 10px;
  /* padding-top:20px ; */
}

/deep/ h2 {
  font-size: 0.4rem;
}

/deep/ .md-tab-bar-item {
  font-size: 32px;
}

/deep/ .md-field-item-control {
  display: flex;
  justify-content: flex-end;
  font-size: 34px;
}

.selfsd {
  margin-top: 60px;
}

.hw_sty {
  position: fixed;
  right: 0;
  bottom: 350px;
  border-top-left-radius: 35px;
  border-bottom-left-radius: 35px;
  background: color-primary;
  color: #fff;
  z-index: 999;
  width: 170px;
  height: 60px;
  line-height: 60px;
  text-align: center;
}

/deep/ .md-field-item-content::before {
  content: none;
}

.navsetting {
  width: 10vw;
  height: 20vw;
  border-radius: 10px;
  background-color: color-primary;
  position: fixed;
  right: 10px;
  z-index: 999;
  bottom: 40%;
}

.navsetting1 {
  width: 10vw;
  height: 10vw;
  border-radius: 10px;
  background-color: color-primary;
  position: fixed;
  right: 10px;
  z-index: 999;
  bottom: 40%;
}

.backbtn {
  width: 80%;
  height: 50%;
  margin: 0 auto;
  border-bottom: 0.8px solid rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 50px;
  }
}

.backtohome {
  width: 80%;
  height: 50%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: white;
    font-size: 50px;
  }
}
</style>
