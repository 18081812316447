<!--
 * @Descripttion:
 * @version:
 * @Author: 李波
 * @Date: 2020-02-08 17:00:26
 * @LastEditors  : 李波
 * @LastEditTime : 2020-02-10 14:43:01
 -->
<template>
  <div>
<div v-for="(item,index) in jtcy" :key="index">
          <div class="jt_member person" @click="personzd(index)">
            <span v-if="item.relationvalue">{{ item.relationvalue.label }}</span>
            <span v-else>成员{{index+3}}</span>
            <img src="@/assets/abt/img/jtright.png" v-if="!familyzd[index]">
            <img src="@/assets/abt/img/jtdel.png" v-if="familyzd[index]" @click.stop="rydel(index)">
          </div>
          <div v-show="familyzd[index]">
            <md-field>
              <md-field-item solid title="姓名">
                <md-input-item
                  v-model="item.name"
                  align="right"
                  slot="right"
                  placeholder="请填写真实姓名" >
                </md-input-item>
              </md-field-item>
              <md-field-item solid title="关系" :content="RelationValue[index]"
                             @click="showRelation(index)"
                             arrow>
              </md-field-item>
              <!-- 证件关系下拉 -->
              <md-selector
                v-model="isRelation[index]"
                default-value="2"
                :data="ryOptions"
                max-height="320px"
                title="关系类型"
                @show="setShow(index)"
                large-radius
                @choose="showChose"
              ></md-selector>
              <md-field-item solid title="性别" >
                <md-radio-group v-model="item.sex" slot="right">
                  <md-radio-box name="2">男</md-radio-box>
                  <md-radio-box name="1">女</md-radio-box>
                </md-radio-group>
              </md-field-item>
              <md-field-item
                name="name"
                title="出生日期"
                arrow="arrow-right"
                align="right"
                :content="ismeDateValue[index]"
                @click="meDateShow(index)">
              </md-field-item>
              <md-field-item solid title="职业"   >
                <md-input-item
                  v-model="item.zhiye"
                  align="right"
                  slot="right"
                  placeholder="填写职业" >
                </md-input-item>
              </md-field-item>
              <md-field-item solid title="证件类型" :content="rytypeValue[index]"
                             @click="rytypeShow(index)"
                             arrow>
              </md-field-item>
              <!-- 证件类型下拉 -->
              <md-selector
                v-model="rytypelist[index]"
                :data="credtype"
                max-height="320px"
                title="证件类型"
                large-radius
                @show="setShow(index)"
                @choose="rytypeChoose"
              ></md-selector>
              <md-field-item solid title="证件号码"   >
                <md-input-item
                  v-model="item.cardno"
                  align="right"
                  slot="right"
                  placeholder="请填写有效证件号码" >
                </md-input-item>
              </md-field-item>
              <md-button :style="'background:'+getStorage('theme','')" class="btn_bc" size="large" @click="rySave(index)">保存</md-button>
            </md-field>
            <!-- 出生日期弹框 -->
            <md-date-picker
              :ref="'data' + index"
              v-model="ismeDate[index]"
              :min-date="minDate"
              type="custom"
              title="选择出生日期"
              large-radius
              :default-date="defaultdate"
              :custom-types="['yyyy', 'MM','dd']"
              @confirm="rydateConfirm('data'+index,index)"
            ></md-date-picker>
          </div>
        </div>
  </div>
</template>

<script>
import { Tabs, Toast } from 'mand-mobile'
import { menberAdd, delfamily } from '@/api/nbs/nbsinfo/index'
export default {
  components: {
    [Tabs.name]: Tabs,
    [Toast.name]: Toast
  },
  props: {
    jtcy: Array,
    familyzd: Array,
    RelationValue: Array,
    isRelation: Array,
    ismeDateValue: Array,
    ismeDate: Array,
    rytypelist: Array,
    rytypeValue: Array,
    abtComNbsEducation: Array,
    ryOptions: Array,
    credtype: Array
  },
  data () {
    return {
      minDate: new Date('1900/01/01'),
      defaultdate: new Date('2000/01/01')
    }
  },
  methods: {
    // 家庭成员保存
    rySave (index) {
      menberAdd(this.jtcy[index]).then(res => {
        Toast.info('保存成功', 1000)
        this.$parent.getempty()
        this.$parent.getfamily()
      })
    },
    personzd (index) {
      this.familyzd[index] = !this.familyzd[index]
      this.$forceUpdate()
    },
    // 控制打开关系弹窗
    showRelation (index) {
      this.isRelation[index] = true
      this.$forceUpdate()
    },
    // 家庭成员出生日期弹窗开关控制
    meDateShow (index) {
      this.ismeDate[index] = true
      this.$forceUpdate()
    },
    // 人员类型选择弹窗控制
    rytypeShow (index) {
      this.rytypelist[index] = true
      this.$forceUpdate()
    },
    setShow (index) {
      this.tyselect = index
    },
    // 人员删除
    rydel (index) {
      if (this.jtcy[index].id !== undefined) {
        delfamily({ id: this.jtcy[index].id }).then(res => {
          this.familyzd.splice(index, 1)
          this.jtcy.splice(index, 1)
          this.RelationValue.splice(index, 1)
          this.ismeDateValue.splice(index, 1)
          this.rytypeValue.splice(index, 1)
          this.abtComNbsEducation.splice(index, 1)
          this.$parent.znMarri.splice(index, 1)
          this.$parent.getempty()
          this.$parent.getfamily()
          Toast.info('删除成功!', 1000)
        })
      } else {
        this.familyzd.splice(index, 1)
        this.jtcy.splice(index, 1)
        this.RelationValue.splice(index, 1)
        this.ismeDateValue.splice(index, 1)
        this.rytypeValue.splice(index, 1)
        this.abtComNbsEducation.splice(index, 1)
        this.$parent.znMarri.splice(index, 1)
      }
    },
    rytypeChoose ({ text, value }) {
      this.rytypeValue[this.tyselect] = text
      this.jtcy[this.tyselect].cardtype = value
    },
    rydateConfirm (type, val) {
      this.ismeDateValue[val] = this.$refs[type][0].getFormatDate('yyyy-MM-dd')
      this.jtcy[val].birthdate = this.ismeDateValue[val]
    },
    showChose ({ text, value }) {
      this.RelationValue[this.tyselect] = text
      this.jtcy[this.tyselect].relation = value
      let znnum = 0
      this.jtcy.forEach(item => {
        if (item.relation === '03') {
          znnum++
        }
        if (znnum > this.znsum) {
          for (let i = 0; i < (znnum - this.znsum); i++) {
            this.$parent.znMarri.push({
              familyno: this.$parent.familyno,
              name: '',
              yjmoney: '',
              yjages: ''
            })
            this.abtComNbsEducation.push({
              familyno: this.$parent.familyno,
              name: '',
              playschool: '',
              primaryschool: '',
              midschool: '',
              highschool: '',
              university: '',
              graduate: '',
              doctor: ''
            })
          }
        }
      })
    }
  }
}
</script>

 <style lang="stylus" scoped>
.btn_bc{
    margin-top: 1rem;
    color: #FFF;
    background-color: rgb(34, 139, 207);
  }
  .jt_member /deep/ span{
  font-size: 0.4rem;
}
.fa_member .person{
  margin-top: 50px;
}
.jt_member img{
  vertical-align: middle;
  float: right;
  width: 40px;
  margin-top: 3px;
}
 </style>
