<!--
 * @Author: 李波
 * @Date: 2020-02-08 11:40:47
 * @LastEditors: 刘格优
 * @LastEditTime: 2020-05-28 18:49:25
 -->
 <template>
  <div>
    <div>
      <md-tabs @change="changeTab"
               immediate>
        <md-tab-pane name="p1"
                     label="本人">
          <md-field>
            <md-field-item solid
                           title="姓名">
              <md-input-item v-model="manwife[0].name"
                             align="right"
                             slot="right"
                             placeholder="请填写真实姓名">
              </md-input-item>
            </md-field-item>
            <md-field-item solid
                           title="性别">
              <md-radio-group v-model="selfsex"
                              slot="right">
                <md-radio-box name="2">男</md-radio-box>
                <md-radio-box name="1">女</md-radio-box>
              </md-radio-group>
            </md-field-item>
            <md-field-item name="name"
                           title="出生日期"
                           arrow="arrow-right"
                           align="right"
                           :content="selfdate"
                           @click.native="isDatePickerShow = true">
            </md-field-item>
            <md-field-item solid
                           title="职业">
              <md-input-item v-model="manwife[0].zhiye"
                             align="right"
                             slot="right"
                             placeholder="填写职业">
              </md-input-item>
            </md-field-item>
            <md-field-item solid
                           title="证件类型"
                           :content="selectorValue"
                           @click="isSelectorShow = true"
                           arrow="arrow-right">
            </md-field-item>
            <md-field-item solid
                           title="证件号码">
              <md-input-item v-model="manwife[0].cardno"
                             align="right"
                             slot="right"
                             placeholder="请填写有效证件号码">
              </md-input-item>
            </md-field-item>
            <md-button class="btn_bc"
                       :style="'background:'+getStorage('theme','')"
                       size="large"
                       :inactive="btnloading"
                       @click="Save('0')">保存</md-button>
          </md-field>
          <!-- 证件类型下拉 -->
          <md-selector v-model="isSelectorShow"
                       :data="credtype"
                       max-height="320px"
                       title="证件类型"
                       large-radius
                       @choose="onSelectorChoose"></md-selector>
          <!-- 出生日期弹框 -->
          <md-date-picker ref="selfdate"
                          v-model="isDatePickerShow"
                          type="custom"
                          title="选择出生日期"
                          :default-date="defaultdate"
                          large-radius
                          :min-date="minDate"
                          :custom-types="['yyyy', 'MM','dd']"
                          @confirm="onDatePickerConfirm('selfdate')"></md-date-picker>
        </md-tab-pane>
        <md-tab-pane name="p2"
                     label="配偶">
          <md-field>
            <md-field-item solid
                           title="姓名">
              <md-input-item v-model="manwife[1].name"
                             align="right"
                             slot="right"
                             placeholder="请填写真实姓名">
              </md-input-item>
            </md-field-item>
            <md-field-item solid
                           title="性别">
              <md-radio-group v-model="spousesex"
                              slot="right">
                <md-radio-box name="2">男</md-radio-box>
                <md-radio-box name="1">女</md-radio-box>
              </md-radio-group>
            </md-field-item>
            <md-field-item name="name"
                           title="出生日期"
                           arrow="arrow-right"
                           align="right"
                           :content="spousedate"
                           @click.native="DatespouseShow = true">
            </md-field-item>
            <md-field-item solid
                           title="职业">
              <md-input-item v-model="manwife[1].zhiye"
                             align="right"
                             slot="right"
                             placeholder="填写职业">
              </md-input-item>
            </md-field-item>
            <md-field-item solid
                           title="证件类型"
                           :content="spousecard"
                           @click="showSelector"
                           arrow>
            </md-field-item>
            <!-- 证件类型下拉 -->
            <md-selector v-model="isSelectorShow"
                         default-value="2"
                         :data="credtype"
                         max-height="320px"
                         title="证件类型"
                         large-radius
                         @choose="spouseChoose"></md-selector>
            <md-field-item solid
                           title="证件号码">
              <md-input-item v-model="manwife[1].cardno"
                             align="right"
                             slot="right"
                             placeholder="请填写有效证件号码">
              </md-input-item>
            </md-field-item>
            <md-button :style="'background:'+getStorage('theme','')"
                       class="btn_bc"
                       size="large"
                       :inactive="btnloading"
                       @click="Save('1')">保存</md-button>
          </md-field>
          <!-- 出生日期弹框 -->
          <md-date-picker ref="spousedate"
                          v-model="DatespouseShow"
                          type="custom"
                          title="选择出生日期"
                          large-radius
                          :default-date="defaultdate"
                          :min-date="minDate"
                          :custom-types="['yyyy', 'MM','dd']"
                          @confirm="onDatePickerConfirm('spousedate')"></md-date-picker>
        </md-tab-pane>
      </md-tabs>
    </div>
  </div>
</template>

<script>
import { Tabs, Toast } from 'mand-mobile'
import { getStorage } from '@/lib/util'
import { manwifeAdd, menberAdd, addOtherdk, addhouse, showfamilyInfo, addFamilyv, delfamily } from '@/api/nbs/nbsinfo/index'
export default {
  components: {
    [Tabs.name]: Tabs,
    [Toast.name]: Toast
  },
  props: {
    manwife: Array,
    credtype: Array
  },
  data () {
    return {
      minDate: new Date('1900/01/01'),
      defaultdate: new Date('2000/01/01'),
      selfdate: '', // 出生日期赋值
      spousedate: '', // 出生日期赋值
      selfsex: '2',
      spousesex: '1',
      selectorValue: '',
      spousecard: '',
      isSelectorShow: false, // 证件类型下拉是否显示
      isDatePickerShow: false,
      DatespouseShow: false,
      btnloading: false
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.changeTab()
      // temp.style.cssText = `background-color:${getStorage('theme','')} !important`
      // te.style.cssText = `color:${getStorage('theme','')} !important`
      // tem.style.cssText = `color:${getStorage('theme','')} !important`
    })
  },
  updated () {
    this.changeTab()
  },
  methods: {
    // 家庭信息保存
    Save (type) {
      this.btnloading = true
      let data = {}
      if (type === '0') {
        this.manwife[0].sex = this.selfsex
        data = this.manwife[0]
      } else {
        this.manwife[1].sex = this.spousesex
        data = this.manwife[1]
      }
      manwifeAdd(data).then(res => {
        Toast.info('保存成功', 1000)
        this.$parent.getempty()
        this.$parent.getfamily()
      }).finally(() => {
        this.btnloading = false
      })
    },
    // 本人证件类型赋值
    onSelectorChoose ({ text, value }) {
      this.selectorValue = text
      this.manwife[0].cardtype = value
    },
    showSelector () {
      this.isSelectorShow = true
    },
    // 配偶证件类型赋值
    spouseChoose ({ text, value }) {
      this.spousecard = text
      this.manwife[1].cardtype = value
    },
    onDatePickerConfirm (type) {
      if (type === 'selfdate') {
        this.selfdate = this.$refs.selfdate.getFormatDate('yyyy-MM-dd')
      }
      if (type === 'selfdate1') {
        this.selfdate = this.$refs.selfdate1.getFormatDate('yyyy-MM-dd')
      }
      if (type === 'spousedate') {
        this.spousedate = this.$refs.spousedate.getFormatDate('yyyy-MM-dd')
      }
      if (type === 'spousedate1') {
        this.spousedate = this.$refs.spousedate1.getFormatDate('yyyy-MM-dd')
      }
      this.manwife[0].birthdate = this.selfdate
      this.manwife[1].birthdate = this.spousedate
    },
    changeTab () {
      let temp = document.getElementsByClassName('md-tab-bar-ink')
      let tem = document.getElementsByClassName('md-tab-bar-item')
      let te = document.getElementsByClassName('is-active')
      Array.from(temp).forEach(ele => {
        ele.style.cssText = `background-color:${getStorage('theme', '')} !important`
      })
      Array.from(tem).forEach(ele => {
        ele.style.cssText = `color:${getStorage('theme', '')} !important`
      })
      Array.from(te).forEach(ele => {
        ele.style.cssText = `color:${getStorage('theme', '')} !important`
      })
    }
  }
}
</script>

 <style lang="stylus" scoped>
 .btn_bc {
   margin-top: 1rem;
   color: #FFF;
   background-color: rgb(34, 139, 207);
 }
</style>
